dmx.Component('background-video', {

  initialData: {
    paused: false,
  },

  attributes: {
    src: {
      type: String,
      default: null,
    },
  },

  methods: {
    play () {
      this._video.play();
    },

    pause () {
      this._video.pause();
    },

    toggle () {
      if (this._video.paused) {
        this._video.play();
      } else {
        this._video.pause();
      }
    },
  },

  init (node) {
    this._update = this._update.bind(this);

    const videoWrapper = document.createElement('div');
    const videoElement = document.createElement('video');

    videoWrapper.style.setProperty('position', 'absolute');
    videoWrapper.style.setProperty('width', '100%');
    videoWrapper.style.setProperty('height', '100%');
    videoWrapper.style.setProperty('overflow', 'hidden');

    videoElement.style.setProperty('position', 'absolute');
    videoElement.style.setProperty('width', 'auto');
    videoElement.style.setProperty('height', 'auto');
    videoElement.style.setProperty('min-width', '100%');
    videoElement.style.setProperty('min-height', '100%');
    videoElement.style.setProperty('top', '50%');
    videoElement.style.setProperty('left', '50%');
    videoElement.style.setProperty('transform', 'translate(-50%, -50%)');

    videoElement.src = this.props.src;
    videoElement.playsInline = true;
    videoElement.autoplay = true;
    videoElement.muted = true;
    videoElement.loop = true;

    videoElement.setAttribute('muted', '');
    videoElement.setAttribute('playsinline', '');

    videoElement.addEventListener('play', this._update);
    videoElement.addEventListener('pause', this._update);

    const wrapper = document.createElement('div');

    wrapper.style.setProperty('position', 'relative');
    node.style.setProperty('position', 'relative');

    node.parentNode.insertBefore(wrapper, node);
    videoWrapper.appendChild(videoElement);
    wrapper.appendChild(videoWrapper);
    wrapper.appendChild(node);

    this._wrapper = wrapper;
    this._video = videoElement;
  },

  detroy () {
    this._video.removeEventListener('play', this._update);
    this._video.removeEventListener('pause', this._update);
    this._wrapper.remove();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('src')) {
      this._video.src = this.props.src;
    }
  },

  _update (event) {
    this.set('paused', this._video.paused);
  },

});
